<template>
  <div class="mt-16">
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-card class="pa-10">
            <div>
              <v-alert
                :type="type"
              >{{ message }}</v-alert>
            </div>
              <form>
                <v-card-title class="pl-0">
                  Для входа в систему необходимо ввести кодовое слово
                </v-card-title>
                <v-text-field
                  v-model="code"
                  :error-messages="nameErrors"
                  :counter="12"
                  label="Введите кодовое слово"
                  required
                  :color="color"
                ></v-text-field>
                <v-btn
                  class="mr-4"
                  @click="validate"
                >
                  Войти
                </v-btn>
              </form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  data: () => ({
    code: '',
    nameErrors: [],
    auth: false,
    color: 'green',
    type: null,
    message: '',
    authToken: 'ptm21'
  }),
  methods: {
    submit () {
      localStorage.auth = true
    },
    validate () {
      if (this.code === '') {
        this.type = 'warning'
        this.message = 'Введите кодовое слово'
      } else if (this.code !== this.authToken) {
        this.type = 'error'
        this.message = 'Неверное кодовое слово'
      } else {
        localStorage.authToken = this.authToken
        this.submit()
        this.$router.push({ name: 'Dashboard' })
      }
    }
  },
  mounted () {
    // if (this.$route.params.id === this.authToken) {
    //   localStorage.authToken = this.authToken
    //   this.submit()
    //   this.$router.push({ name: 'Dashboard' })
    // }
  }
}
</script>
